<template>
  <div
    class="stories-container"
    @mouseover="showButton=true"
    @mouseleave="showButton=false"
  >
    <Flicking
      :plugins="storyPlugin"
      class="story-flicker"
    >
      <div
        class="story-card-panel"
        :class="{'visible': showButton }"
      >
        <tds-icon
          :disabled="!readyToView"
          size="30px"
          name="star"
          @click="toggleModal()"
        />
      </div>
    </Flicking>
  </div>
  <ModalComponent
    v-if="readyToView"
    :is-open="isActive"
    name="login-modal"
    :class="isActive ? 'swing-in-top-fwd': 'swing-out-top-fwd'"
    @modal-close="closeModal"
  >
    <template #header />
    <template #content>
      <Flicking
        :options="{ circular: true }"
        :plugins="pluginWrapper"
        class="main-flicker"
      >
        <div
          key="card-panel-1"
          class="card-panel"
        >
          <Flicking
            ref="places"
            :options="{ panelsPerView: 1, circular: true, nested: true, align: 'next', autoResize: true }"
            :plugins="plugins"
          >
            <aggregate-card
              key="panel-1"
              class="flicking-panel column-direction"
            >
              <template #content>
                <h4>{{ translate("data->Here's the sites with") }}</h4>
                <br>
                <h1>{{ translate("data->longest") }}</h1>
                <br>
                <h4>{{ translate("data->turn around times.") }}</h4>
                <span class="emoji big-emoji">📍💸</span>
              </template>
            </aggregate-card>
            <aggregate-card
              key="panel-2"
              class="flicking-panel column-direction"
            >
              <template #content>
                <div class="header">
                  <h1>{{ roundStringToNearestInt(percentDurationTopLocation) }}%</h1>
                  <p>{{ translate("data->of your standstill time last 30 days was at these sites.") }}</p>
                </div>
                <div class="category-list">
                  <div
                    v-for="item in topLocations"
                    :key="item.hexagons"
                    class="category-item"
                  >
                    <span
                      class="category-size"
                      :style="{width: (roundStringToNearestInt(item.sum_duration) / totalDaySaved) * 100 + '%'}"
                    />
                    <span class="emoji">🏠</span>
                    <div
                      class="category-details"
                    >
                      <h2>{{ item.hexagon_name.length > 0 ? item.hexagon_name : item.hexagons }}</h2>
                      <p>{{ roundStringToNearestInt(item.sum_duration) }} {{ translate("data->days") }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </aggregate-card>
            <aggregate-card
              key="panel-3"
              class="flicking-panel column-direction"
            >
              <template #content>
                <div class="header">
                  <p> {{ translate("data->Compare your standstill with others.") }}</p>
                </div>
                <div
                  v-if="fleetHourPercent < othersHourPercent"
                  class="header"
                >
                  <h1>🎉</h1>
                  <h2>{{ translate("data->Awesome!") }}</h2>
                  <h5>{{ translate("data->You're doing better than the average fleet!") }}</h5>
                </div>
                <div
                  v-else-if="fleetHourPercent === othersHourPercent"
                  class="header"
                >
                  <h1>👏</h1>
                  <h5>{{ translate("data->Cool! You're like the average fleet!") }}</h5>
                </div>
                <div
                  v-else-if="fleetHourPercent > othersHourPercent"
                  class="header"
                />
                <div class="category-list">
                  <div class="category-item">
                    <span
                      class="category-size category-fleet your-fleet"
                      :style="{width: fleetHourPercent + '%'}"
                    />
                    <span class="emoji">🚛</span>
                    <div
                      class="category-details"
                    >
                      <h6>{{ translate("data->Your fleet") }}</h6>
                      <h2>{{ fleetHourPercent }} %</h2>
                    </div>
                  </div>
                  <div class="category-item">
                    <span
                      class="category-size category-fleet avg-fleet"
                      :style="{width: othersHourPercent + '%'}"
                    />
                    <span class="emoji">🚚</span>
                    <div
                      class="category-details"
                    >
                      <h6>{{ translate("data->Average fleet") }}</h6>
                      <h2>{{ othersHourPercent }} %</h2>
                    </div>
                  </div>
                  <div class="category-item">
                    <span
                      class="category-size category-fleet top-fleet"
                      :style="{width: top20HourPercent + '%'}"
                    />
                    <span class="emoji">🏆</span>
                    <div
                      class="category-details"
                    >
                      <h6>{{ translate("data->Top 20") }}</h6>
                      <h2>{{ top20HourPercent }} %</h2>
                    </div>
                  </div>
                </div>
              </template>
            </aggregate-card>
            <aggregate-card
              key="panel-4"
              class="flicking-panel column-direction"
            >
              <template #content>
                <div class="header">
                  <p>{{ translate("data->You would have saved") }}</p>
                  <h1>{{ roundStringToNearestInt(potentialSaving) }} {{ translate("data->days") }}</h1>
                  <p>{{ translate("data->last 30 days if you had optimised your standstill there.") }}</p>
                </div>
                <div class="category-list">
                  <div
                    v-for="item in topLocations"
                    :key="item.hexagons"
                    class="category-item"
                  >
                    <span
                      class="category-size"
                      :style="{width: (roundStringToNearestInt(item.potential_saving) / roundStringToNearestInt(potentialSaving)) * 100 + '%'}"
                    />
                    <span class="emoji">🏠</span>
                    <div
                      class="category-details"
                    >
                      <h2>{{ item.hexagon_name.length > 0 ? item.hexagon_name : item.hexagons }}</h2>
                      <p>{{ roundStringToNearestInt(item.potential_saving) }} {{ translate("data->days") }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </aggregate-card>
            <template #viewport>
              <div class="flicking-pagination" />
            </template>
          </Flicking>
          <div class="flicking-footer" />
        </div>
        <div
          key="card-panel-2"
          class="card-panel"
        >
          <Flicking
            ref="something"
            :options="{ panelsPerView: 1, circular: true, nested: true, align: 'next', autoResize: true }"
            :plugins="plugins2"
          >
            <aggregate-card
              key="panel-1"
              class="flicking-panel column-direction"
            >
              <template #content>
                <h4>{{ translate("data->Here's your") }}</h4>
                <br>
                <h1>{{ translate("data->worst") }}</h1>
                <br>
                <h4>{{ translate("data->trucks") }}</h4>
                <span class="emoji big-emoji">🚚🚛</span>
              </template>
            </aggregate-card>
            <template #viewport>
              <div class="flicking-pagination" />
            </template>
          </Flicking>
        </div>
        <div
          key="card-panel-3"
          class="card-panel"
        >
          <Flicking
            ref="season"
            :options="{ panelsPerView: 1, circular: true, nested: true, align: 'next', autoResize: true }"
            :plugins="plugins3"
          >
            <aggregate-card
              key="panel-1"
              class="flicking-panel column-direction"
            >
              <template #content>
                <h4>{{ translate("data->Here's what we see") }}</h4>
                <br>
                <h1>{{ translate("data->this year") }}</h1>
                <br>
                <h4>{{ translate("data->will bring.") }}</h4>
                <span class="emoji big-emoji">🔮</span>
              </template>
            </aggregate-card>
            <template #viewport>
              <div class="flicking-pagination" />
            </template>
          </Flicking>
        </div>
      </Flicking>
    </template>
    <template #footer />
  </ModalComponent>
</template>

<script setup>
import {computed, ref, watchEffect} from "vue"
import Flicking from "@egjs/vue3-flicking"
import { Pagination, Perspective, AutoPlay } from "@egjs/flicking-plugins"
import AggregateCard from "@/components/AggregateCard.vue"
import ModalComponent from "@/components/ModalComponent.vue"
import {useSetStore} from "@/store/setStore"
import {useI18nStore} from "@/store/localeStore"


const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const showButton = ref(true)
const dataStore = useSetStore()

function roundStringToNearestInt(str) {
  return Math.round(parseFloat(str))
}

const topLocations = computed(() => {
  const locations = dataStore.getAggregate("topLocations")
  return Array.isArray(locations) ? locations : []
})

const totalDaySaved = computed(() => {
  return Number.parseInt(topLocations.value.reduce((acc, curr) => acc + curr["sum_duration"], 0))
})

const fleetHourPercent = computed(() => {
  const storyData = dataStore.getAggregate("storyData")
  if (Array.isArray(storyData) && storyData.length > 0 && "daily_hour_per_vehicle" in storyData[0]) {
    return roundStringToNearestInt(storyData[0]["daily_hour_per_vehicle"])
  }
  return false
})

const othersHourPercent = computed(() => {
  const kpiData = dataStore.getAggregate("kpiLocation")
  if (Array.isArray(kpiData) && kpiData.length > 0 && "median_daily_hour_per_vehicle" in kpiData[0]) {
    return roundStringToNearestInt(kpiData[0]["median_daily_hour_per_vehicle"])
  }
  return false
})

const top20HourPercent = computed(() => {
  const kpiData = dataStore.getAggregate("kpiLocation")
  if (Array.isArray(kpiData) && kpiData.length > 0 && "low_20_daily_hour_per_vehicle" in kpiData[0]) {
    return roundStringToNearestInt(kpiData[0]["low_20_daily_hour_per_vehicle"])
  }
  return false
})

const percentDurationTopLocation = computed(() => {
  const storyData = dataStore.getAggregate("storyData")
  if (Array.isArray(storyData) && storyData.length > 0 && "percent_duration_on_top_3" in storyData[0]) {
    return roundStringToNearestInt(storyData[0]["percent_duration_on_top_3"])
  }
  return false
})

const potentialSaving = computed(() => {
  const storyData = dataStore.getAggregate("storyData")
  if (Array.isArray(storyData) && storyData.length > 0 && "potential_saving_on_top_3" in storyData[0]) {
    return storyData[0]["potential_saving_on_top_3"]
  }
  return false
})

const readyToView = computed(() => {
  return topLocations.value && percentDurationTopLocation.value && potentialSaving.value
})

const dialog = ref()
const isActive = ref(true)
const places = ref(null)
const season = ref(null)
const something = ref(null)

const plugins = ref([new AutoPlay({ duration: 10000, direction: "NEXT", stopOnHover: true }), new Pagination({ type: "bullet" })])
const plugins2 = ref([new Pagination({ type: "scroll" })])
const plugins3 = ref([new Pagination({ type: "scroll" })])
const storyPlugin = ref()
const pluginWrapper = ref([new Perspective({ scale: 2, rotate: 0.3 })])
function toggleModal () {
  isActive.value = !isActive.value
}

watchEffect(() => {
  if (isActive.value && dialog.value) {
    dialog.value.showModal()
  } else if (dialog.value) {
    dialog.value.close()
  }
})
const openModal = () => {
  isActive.value = true
}
const closeModal = () => {
  isActive.value = false
}
</script>

<style scoped>
@import '~@egjs/vue3-flicking/dist/flicking.css';
@import '~@egjs/flicking-plugins/dist/flicking-plugins.css';
@import '~@egjs/flicking-plugins/dist/arrow.css';
@import '~@egjs/flicking-plugins/dist/pagination.css';

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.swing-out-top-fwd {
  -webkit-animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
  animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

@-webkit-keyframes swing-out-top-fwd {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-fwd {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
    transform: rotateX(70deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}

.stories-container {
  position: fixed;
  top: 5px;
  left: 40%;
  display: flex;
  z-index: 4;
}

.story-card-panel {
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(to right, hotpink, blue); /* Gradient from pink to blue */
  padding: 2px; /* Adjust for border size */
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  transition: opacity 400ms;
  transition-delay: 300ms;
  opacity: 1;
  width: 100%;
  height: auto;
}

.story-card-panel tds-icon {
  color: hotpink;
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 50%;
  padding: 2px;
}

.card-panel {
  width: 600px;
  height: 600px;
  font-family: 'Arial', sans-serif;
  color: white;
  background-color: var(--tds-blue-900);
  border-radius: 5px;
  padding: 20px;
}

.flicking-panel {
  display: flex;
  flex-direction: row;
}

.row-direction {
  display: flex;
  flex-direction: row;
}

.column-direction {
  display: flex;
  flex-direction: column;
}

.header h1 {
  margin-bottom: 10px;
}

.category-list {
  margin-top: 20px;
}

.category-item {
  display: flex;
  align-items: center;
  border: var(--tds-blue-50) 1px solid;
  min-width: 350px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  text-align: start;
}

.category-item.selected {
  background-color: var(--tds-blue-400);
  border: 2px solid #6f6;
}

.category-details {
  z-index: 1;
  background-color: transparent;
}

.emoji {
  z-index: 1;
  font-size: 25px;
  margin-right: 10px;
}

.big-emoji {
  font-size: 100px;
}

.category-details h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.category-details p {
  font-size: 16px;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #fff;
  }
}

.aggregate-card-container {
  margin-top: 100px;
  background-color: var(--tds-blue-900);
  color: white;
}

.category-size {
  height: 100%;
  background-color: var(--tds-blue-400);
  position: absolute;
  left: 0;
  bottom: 0;
  align-self: stretch;
  border-radius: 10px;
  margin-left: 1px;
}

.your-fleet {
  background: linear-gradient(to right, transparent, var(--tds-blue-400));
}

.avg-fleet {
  background: linear-gradient(to right, transparent, var(--tds-grey-600));
}

.top-fleet {
  background: linear-gradient(to right, transparent, var(--tds-green-400));
}

</style>

<style>
.flicking-pagination {
  top: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.flicking-pagination-bullet {
  flex: 1;
  text-align: center;
  border-radius: 0;
  background-color: var(--tds-grey-600);
}

.flicking-pagination-bullet-active {
  background-color: hotpink;
}
</style>
