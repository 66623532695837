<script setup>
import { log } from "@/plugin/logger"
import { ref, computed } from "vue"
import { authService } from "@/services/AuthService"
import AuthChoiceModal from "@/components/AuthChoiceModal.vue"
import {useMainStore} from "@/store/mainStore"
import UserInputForm from "@/components/UserInputForm.vue"

const mainStore = useMainStore()
const isOpen = ref(false)
const openFeedbackForm = ref(false)
const isLoggedIn = computed(()=> {
  return authService.keycloak.authenticated
})

const user = computed(() => {
  return authService.keycloak.idTokenParsed.given_name.substring(0, 2)
})
const options = 
    [
        { label: "Profile", value: "profile" },
        { label: "Log out", value: "logout" }
    ]
const selected = ref(null)


function selectOption (option) {
  selected.value = option
  isOpen.value = false
  switch(option.value) {
    case "logout":
      log("info", "Logout")
      window.location.href = process.env.VUE_APP_KEYCLOAK_URL + "/auth/realms/fg-ext/protocol/openid-connect/logout?redirect_uri=" + window.origin
      break
    case "profile":
      log("info", "go to my scania profile")
      window.open("https://fmp-administration.cs.scania.com/#/administration/settings/profile", "_blank")
      break
    default:
  }
}

const notificationFlag = computed(() => {
  return mainStore.getNotificationFlag
})

const openNotification = () => {
  mainStore.setNotificationFlag(false)
}

</script>

<template>
  <div
    v-if="isLoggedIn"
    class="navbar-account navbar-icon-button"
  >
    <button
      id="feedback-button"
      class="navbar__open-button navbar__open-action"
      @click="openFeedbackForm = !openFeedbackForm"
    >
      <font-awesome-icon icon="comment" />
    </button>
    <user-input-form :visible="openFeedbackForm" />
    <button
      v-if="notificationFlag"
      id="notification_button"
      class="navbar__open-button navbar__open-action"
      @click="openNotification"
    >
      <font-awesome-icon icon="bell" />
    </button>
    <button
      id="profile_button"
      class="navbar__open-button navbar__open-action"
      @click="isOpen=!isOpen"
    >
      <div class="navbar-account__initials text-light">
        <span>{{ user }}</span>
        <div
          class="navbar-account__image"
        />
      </div>
    </button>

    <div
      v-if="isOpen"
      class="navbar__list-group navbar__system-menu"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="navbar__list-group-item navbar__dropdown-user-info"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
  <div v-else>
    <auth-choice-modal />
  </div>
</template>

<style scoped>
.navbar-account {
  width: 100%;
}

.navbar__open-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px;
  position: relative;
  width: 66px;
  z-index: 1001;
  color: rgb(255, 255, 255);
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  background: none;
  box-shadow: transparent 0px 0px 0px;
  border: 0px solid transparent;
  cursor: pointer;
  outline: none;
  text-shadow: transparent 0px 0px 0px;
  margin-bottom: 10px;
}
.navbar-account__initials {
  background-color: var(--tds-blue-800);
  line-height: 1em;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 34px;
  overflow: hidden;
  width: 34px;
}

.navbar-account__initials.text-light {
   color: #fff !important;
}

.navbar-account__image {
  width: 34px;
  height: 34px;
  position: absolute;
  background-size: cover;
}

a {
  color: var(--tds-grey-900);
}

a:hover {
   text-decoration: none;
   color: var(--tds-grey-600);
}

.navbar__dropdown-user-info {
  display: inline-block;
  color: white;
  border-top: 1px solid #CCC;
  background-color: rgb(4, 30, 66);
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  cursor: pointer;
  vertical-align: middle;
  width: 66px;
  padding: 3px;
}
.navbar__dropdown-user-info:hover {
  background-color: rgb(15, 50, 99);
}

strong {
  color: var(--tds-grey-900);
}

p {
  color: var(--tds-blue-300);
  margin-bottom: 0;
}

.navbar__dropdown-item {
  padding: 24px 16px;
  border: 1px white;
}

.navbar__dropdown-item:hover {
   background-color: var(--tds-blue-50);
 }

.navbar__dropdown-item a {
  display: block;
}

.navbar__dropdown-menu--visible {
  display: block;
  opacity: 1;
}
.scania-icon {
  font-size: 20px;
  color: black;
}
.fa-icon {
  font-size: 20px;
  color: black;
}
</style>
