class WorkerManager {
  constructor() {
    this.workers = {}
    this.initWorkers()
    this.listeners = {}
  }

  initWorkers() {
    // Creating worker instances for various APIs
    const workerNames = [
      "edgesAPI",
      "hexesAPI",
      "edges7DaysAPI",
      "hexes7DaysAPI",
      "stopDuration7DaysAPI",
      "comparedStopDuration7DaysAPI",
      "stopDurationPredictionAPI",
      "weightUnloaded7DaysAPI",
      "comparedWeightUnloaded7DaysAPI",
      "weightUnloadedPredictionAPI",
      "stopTimeDataAPI",
      "barChartDataAPI",
      "weekdayChartDataAPI",
      "duration7DaysAPI",
      "comparedDuration7DaysAPI",
      "durationPredictionAPI",
      "unloadedWeight7DaysAPI",
      "comparedWeight7DaysAPI",
      "predictedWeightUnloadAPI",
      "dataForStoryComponent",
      "topLocations",
      "kpiLocation",
      "optimalTrip",
      "vehicleRegNos"
    ]

    workerNames.forEach(name => {
      this.workers[name] = new Worker(
        new URL("../web-worker/duckdbWorker.js", import.meta.url),
        { type: "module" }
      )
    })
  }

  getWorker(name) {
    return this.workers[name]
  }

  registerListener(name, callback) {
    this.listeners[name] = callback
    this.workers[name].onmessage = (event) => {
      if (this.listeners[name]) {
        this.listeners[name](event)
      }
    }
  }

  deregisterListener(name) {
    if (this.listeners[name]) {
      delete this.listeners[name]
    }
  }
}

export const workerManager = new WorkerManager()
