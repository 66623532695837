<script setup>
import BaseMap from "@/components/BaseMap"
import { ref, computed, onUnmounted, onMounted} from "vue"
import { log } from "@/plugin/logger.js"
import {useMainStore} from "@/store/mainStore"

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
const mainStore = useMainStore()

const mapboxToken = process.env.VUE_APP_MAP_TOKEN

const zoom = ref(1.62)
const position = ref([0, 0])
const content = ref()
const baseMap = ref()

const mapStyle = computed(() => {
  return "mapbox://styles/" + mainStore.getSelectedBaseMap
})

onMounted(() => {
  log("info", "Initiated a 'EmptyMapAppView'.")
  emit("baseMapUpdated", baseMap.value?.map)
})

onUnmounted(() => {
  emit("baseMapRemoved")
})

</script>

<template>
  <BaseMap
    ref="baseMap"
    class="map"
    :access-token="mapboxToken"
    :map-style="mapStyle"
    :zoom="zoom"
    :max-zoom="18"
  />
</template>

<style>
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  word-wrap: break-word;
  width: 300px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 10px;
  font-family: 'Scania Sans';
  word-break: break-word;

}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:hover {
  background-color: pink;
}
</style>
