<template>
  <div
    class="tooltipContainer"
    :class="{ activeTooltip: showTooltip}"
    :style="{ top: tooltip.y, left: tooltip.x }"
  >
    <slot
      v-if="showTooltip"
      name="tooltip"
      :lines="tooltip.values"
    >
      <span
        v-for="(value, key) in tooltip.values"
        :key="key"
        class="tooltip-text"
      >
        {{ key }} {{ value }}
      </span>
    </slot>
  </div>
  <figure class="svg-container">
    <svg
      ref="svgContainer"
      class="svg-content"
      :viewBox="viewBox"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        v-if="chartProps.showTitle"
        :transform="`translate(${chartProps.width / 2}, ${margin.top})`"
      >
        <text class="chartTitle">
          {{ translate(graphTitle) }}
        </text>
      </g>
      <g :transform="`translate(${margin.right*2.5}, ${chartProps.height-margin.left-60})`">
        <text class="yAxisLabel">
          {{ translate(yAxisLabel) }}
        </text>
      </g>
      <g :transform="`translate(${chartProps.width - margin.left + 20 }, ${(chartProps.height+100)-margin.bottom})`">
        <text class="xAxisLabel">
          {{ translate(xAxisLabel) }}
        </text>
      </g>
      <template v-if="direction === 'vertical'">
        <template v-if="showTooltip">
          <g :transform="`translate(${tooltip.x}, ${tooltip.y})`">
            <line
              class="tooltip-line"
              x1="0"
              y1="-80"
              x2="0"
              :y2="80"
            />
          </g>
        </template>
        <transition-group tag="g">
          <g
            v-for="(bargroup, id) in groups"
            :key="generateVueUID(id)"
            :transform="`translate(${bandScale(bandAxisTicks[id])}, 0)`"
          >
            <path
              v-for="(value, subgroup, index) in bargroup"
              :key="generateVueUID(index)"
              :d="roundedTopRectPath(bandSubgroupScale(subgroup), linearScale(value), bandSubgroupScale.bandwidth(), linearScale(0) - linearScale(value))"
              :fill="color(subgroup)"
              class="bar"
              @click="populatePathTooltip($event, subgroup, value, id)"
            />
          </g>
        </transition-group>
        <g
          v-barchartaxis="{scale: bandScale, tickLabels: bandAxisTickLabels, direction: 'vertical', yTickFormat: yTickFormat, subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(0, ${chartProps.height - margin.bottom})`"
        />
        <g
          v-linearaxis="{scale: linearScale, direction: 'vertical', subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(${margin.left}, 0)`"
        />
      </template>
      <template v-else>
        <transition-group tag="g">
          <g
            v-for="(bargroup, id) in groups"
            :key="generateVueUID(id)"
            :transform="`translate(0 ,${bandScale(bandAxisTicks[id])})`"
          >
            <path
              v-for="(value, subgroup, index) in bargroup"
              :key="generateVueUID(index)"
              :d="roundedRightRectPath(linearScale(0), bandSubgroupScale(subgroup), linearScale(value) - linearScale(0), bandSubgroupScale.bandwidth())"
              :fill="color(subgroup)"
              class="bar"
              @click="populatePathTooltip($event, subgroup, value, id)"
              @mouseout="removeTooltip()"
            />
          </g>
        </transition-group>
        <g
          v-linearaxis="{scale: linearScale, direction: 'horizontal', subSampleXAxis: shouldSubSampleAxis}"
          :transform="`translate(0, ${barAxisLocation === 'top' ? margin.top : chartProps.height - margin.bottom})`"
          class="axis"
        />
        <g
          v-barchartaxis="{scale: bandScale, direction: 'horizontal', yTickFormat: yTickFormat, subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(${margin.left}, 0)`"
        />
      </template>
    </svg>
  </figure>
</template>

<script setup>
import * as d3 from "d3"
import {computed, reactive, ref, onMounted, inject} from "vue"
import {log} from "@/plugin/logger"
import {useSetStore} from "@/store/setStore"
import {useI18nStore} from "@/store/localeStore"


const props = defineProps({
  namedModule: {
    type: String,
    default: ""
  },
  showThis: {
    type: Boolean,
    default: true
  },
  colourThemeBars: {
    type: String,
    default: "var(--tds-blue-400)"
  },
  colourThemePopup: {
    type: String,
    default: "var(--tds-blue-800)"
  },
  getStateFunction: {
    type: String,
    required: true
  },
  structKey: {
    type: String,
    required: true
  },
  popupUnit: {
    type: String,
    required: true
  },
  graphTitle: {
    type: String,
    default: "This will be a graph title, that can be long"
  },
  width: {
    type: Number,
    default: 600
  },
  height: {
    type: Number,
    default: 170
  },
  xKey: {
    type: String,
    default: ""
  },
  yKey: {
    type: String,
    default: ""
  },
  zKey: {
    type: String,
    default: ""
  },
  xAxisLabel: {
    type: String,
    default: ""
  },
  yAxisLabel: {
    type: String,
    default: ""
  },
  legend: {
    type: Boolean,
    default: false
  },
  direction: {
    type: String,
    default: "vertical",
    validator: function (value) {
      return ["vertical", "horizontal"].indexOf(value) !== -1
    }
  },
  barAxisLocation: {
    type: String,
    default: "bottom",
    validator: function (value) {
      return ["top", "bottom"].indexOf(value) !== -1
    }
  },
  paddingBetweenBars: {
    type: Number,
    default: 0.5,
    validator: function (value) {
      return value >= 0 && value <= 1
    }
  },
  paddingBetweenGroups: {
    type: Number,
    default: 0.2,
    validator: function (value) {
      return value >= 0 && value <= 1
    }
  },
  xTickFormat: {
    type: Function,
    default: null
  },
  yTickFormat: {
    type: Function,
    default: null
  },
  xMin: {
    type: Number,
    default: null
  },
  xMax: {
    type: Number,
    default: null
  },
  yMin: {
    type: Number,
    default: null
  },
  yMax: {
    type: Number,
    default: null
  },
  useOnlyXKey: {
    type: Boolean,
    default: false
  },
  tooltipLabel: {
    type: String,
    default: ""
  }
})

const dataStore = props.namedModule.length > 0 ? inject(props.namedModule) : useSetStore()
const padding = ref(60)
const showTooltip = ref(false)
const colours = ref(["#440154FF", "#404788FF", "#287D8EFF", "#20A387FF", "#73D055FF", "#FDE725FF"])
const svgContainer = ref()
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

// Group related properties
const chartProps = reactive({
  structKey: props.structKey,
  getStateFunction: props.getStateFunction,
  fontSize: "0.7rem",
  graphTitle: props.graphTitle,
  width: props.width,
  height: props.height,
  xKey: props.xKey,
  yKey: props.yKey,
  zKey: props.zKey,
  xAxisLabel: props.xAxisLabel,
  yAxisLabel: props.yAxisLabel,
  margin: { top: 30, right: 10, bottom: 20, left: 30 },
  xAxisLabelShift: { dx: 90, dy: 85 },
  yAxisLabelShift: { dx: -40, dy: -55 },
  xAxisTickLabelFontSize: "11px",
  direction: props.direction,
  paddingBetweenBars: props.paddingBetweenBars,
  paddingBetweenGroups: props.paddingBetweenGroups,
  barAxisLocation: props.barAxisLocation,
  xTickFormat: props.xTickFormat,
  yTickFormat:props.yTickFormat,
  xMin: props.xMin,
  xMax: props.xMax,
  yMin: props.yMin,
  yMax: props.yMax,
  useOnlyXKey: props.useOnlyXKey,
  showTitle: false,
  tooltipLabel:props.tooltipLabel
})

const tooltip = ref({
  x: 0,
  y: 0,
  selectedPath: null,
  values: {}
})

function getMax (array) {
  return d3.max(array.map(item => d3.max(Object.values(item))))
}

function generateVueUID () {
  return Math.random(Number.MAX_SAFE_INTEGER)
}

function removeTooltip () {
  showTooltip.value = false
}

const plotData = computed(() => {
  return dataStore[chartProps.getStateFunction](chartProps.structKey)

})

const groupKeys = computed(() => {
  let groupKeys = Object.keys(plotData.value[0]).filter(item => item !== chartProps.xKey)
  if (chartProps.useOnlyXKey) {
    groupKeys = [ chartProps.yKey ]
  }
  return chartProps.direction === "vertical" ? groupKeys : groupKeys.reverse()
})

const groups = computed(() => {
  if (chartProps.useOnlyXKey) {
    return plotData.value.map(data => ({ [ chartProps.yKey ]: data[chartProps.yKey] }))
  }
  return plotData.value.map(({ [ chartProps.xKey ]: name, ...rest }) => rest)
})

const groupsAlias = computed(() => {
  if (chartProps.useOnlyXKey) {
    return plotData.value.map(data => ({ [ data[chartProps.yKey] ]: data[chartProps.tooltipLabel] }))
  }
  return plotData.value.map(({ [ chartProps.xKey ]: name, ...rest }) => rest)
})

const shouldSubSampleAxis = computed(() => {
  return groups.value.length > 29
})

const width = computed(() => {
  const strokeWidth = 130
  return chartProps.width - chartProps.margin.left - chartProps.margin.right - (strokeWidth * 2)
})

const height = computed(() => {
  const strokeWidth = 25
  return chartProps.height - chartProps.margin.top - chartProps.margin.bottom - (strokeWidth * 2)
})

const viewBox = computed(() => {
  return `0 0 ${chartProps.width} ${chartProps.height}`
})

const bandAxisTicks = computed(() => {
  if(plotData.value) return plotData.value.map(item => item[chartProps.xKey])
  return null
})

const bandAxisTickLabels = computed(() => {
  if(plotData.value) return plotData.value.map(item => item[chartProps.zKey])
  return null
})

const color = computed(() => {
  return d3.scaleOrdinal().range(colours.value)
})

const bandScale = computed(() => {
  const bandScale = d3.scaleBand()
      .domain(bandAxisTicks.value)
      .padding(chartProps.paddingBetweenGroups)

  return chartProps.direction === "vertical"
      ? bandScale.range([chartProps.margin.left, chartProps.width - chartProps.margin.right])
      : bandScale.range([chartProps.margin.top, chartProps.height - chartProps.margin.bottom])
})

const bandSubgroupScale = computed(() => {
  const bandScaleLoc = d3.scaleBand()
      .domain(groupKeys.value)
      .padding(chartProps.paddingBetweenBars)

  return chartProps.direction === "vertical"
      ? bandScaleLoc.rangeRound([0, bandScale.value.bandwidth()])
      : bandScaleLoc.rangeRound([bandScale.value.bandwidth(), 0])
})

const margin = computed(() => {
  return chartProps.margin
})

const linearScale = computed(() => {
  // determine whether x or y min/max scale limits should be applied based on direction of chart
  const values = chartProps.direction === "vertical" ? [chartProps.yMin, chartProps.yMax] : [chartProps.xMin, chartProps.xMax]
  const minValue = values[0] ? values[0] : 0
  const maxValue = values[1] ? values[1] : getMax(groups.value)

  const linearScale = d3.scaleLinear()
      .domain([minValue, maxValue])
      .nice()
  return chartProps.direction === "vertical"
      ? linearScale.range([chartProps.height - chartProps.margin.bottom, chartProps.margin.top])
      : linearScale.range([chartProps.margin.left, chartProps.width - chartProps.margin.right])
})

const roundedRightRectPath = (x, y, width, height) => {
  const r = 8
  const effectiveRadius = Math.min(r, height / 2)
  return `M${x},${y}
          h${width - effectiveRadius}
          a${effectiveRadius},${effectiveRadius} 0 0 1 ${effectiveRadius},${effectiveRadius}
          v${height - 2 * effectiveRadius}
          a${effectiveRadius},${effectiveRadius} 0 0 1 ${-effectiveRadius},${effectiveRadius}
          h${-width + effectiveRadius}
          z`
}
const roundedTopRectPath = (x, y, width, height) => {
  const r = 8
  const effectiveRadius = Math.min(r, height / 2)
  return `M${x},${y + effectiveRadius}
          a${effectiveRadius},${effectiveRadius} 0 0 1 ${effectiveRadius},${-effectiveRadius}
          h${width - 2 * effectiveRadius}
          a${effectiveRadius},${effectiveRadius} 0 0 1 ${effectiveRadius},${effectiveRadius}
          v${height - effectiveRadius}
          h${-width}
          z`
}

function formatDate(dateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const [year, month, day] = dateString.split("-")

  // Convert day to a number and get the ordinal suffix
  const dayNumber = parseInt(day, 10)
  let ordinalSuffix = "th"
  if (dayNumber === 1 || dayNumber === 21 || dayNumber === 31) {
    ordinalSuffix = "st"
  } else if (dayNumber === 2 || dayNumber === 22) {
    ordinalSuffix = "nd"
  } else if (dayNumber === 3 || dayNumber === 23) {
    ordinalSuffix = "rd"
  }

  return `${dayNumber}${ordinalSuffix} ${months[parseInt(month, 10) - 1]}`
}

function populatePathTooltip(evt, key, valueNum, id) {
  // Calculate the center of the bar
  const barCenterX = (bandScale.value(bandAxisTicks.value[id]) + bandSubgroupScale.value.bandwidth() * 1.5)

  // Clear highlight from the previously selected path
  if (tooltip.value.selectedPath) {
    tooltip.value.selectedPath.classList.remove("highlighted")
  }

  // Highlight the newly selected path
  tooltip.value.selectedPath = evt.currentTarget
  evt.currentTarget.classList.add("highlighted")

  // Set tooltip position and content
  tooltip.value.x = barCenterX // Center of the bar
  tooltip.value.y = margin.value.top
  const keyProp = formatDate(plotData.value[id][chartProps.xKey])
  tooltip.value.text = { [keyProp]: getValuesByKey(groupsAlias.value,valueNum) }

  tooltip.value.values = { [keyProp]: "", [getValuesByKey(groupsAlias.value,valueNum) + " " + props.popupUnit]: ""}
  showTooltip.value = true
  log("info", "{\"populateTooltip\":\"Clicked data in Bar Chart\"}")
}
function getValuesByKey(arr, key) {
  return arr.map(obj => obj[key]).filter(value => value!= undefined)
}
function openPopup () {
  if (groups.value.length > 0) {
    const item = groups.value[groups.value.length - 1]
    const lastItemId = groups.value.length - 1

    // Mock event object
    const paths = svgContainer.value.querySelectorAll("path.bar")
    const lastPath = paths[paths.length - 1]
    const mockEvent = {currentTarget: lastPath}

    // Trigger the tooltip function
    populatePathTooltip(mockEvent, Object.keys(item)[0], Object.values(item)[0], lastItemId)
  }
}

onMounted(() => {
  openPopup()
})

</script>

<script>
import { barchartaxis } from "@/directives/barchartaxis.js"
import { linearaxis } from "@/directives/linearaxis.js"

export default {
  directives: {
    barchartaxis,
    linearaxis
  }
}
</script>


<style scoped>
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 0.90rem;
  fill: var(--tds-grey-800);
}
.xAxisLabel {
  text-anchor: end;
  font-size: 0.90rem;
  fill: var(--tds-grey-800);
}

.tooltipContainer {
  font-size: 0.7rem;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  color: var(--tds-white);
  opacity: 0;
  pointer-events: none;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 8px;
  padding: 8px;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  background-color: v-bind(colourThemePopup);
  width: 100%;
  height: 45px;
  text-align: center;
  justify-items: center;
}

.activeTooltip {
  opacity: 0.95;
  z-index: 6;
}
.tooltipContainer span {
  display: block;
}

.svg-container {
  width: 100%;
  vertical-align: top;
  display: block;
  background-color: white;
  color: var(--tds-grey-600);
  font-weight: 300;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.axis {
  color: var(--tds-grey-600);
}

.tooltip-line {
  stroke: var(--tds-grey-600);
  stroke-width: 2;
}

.bar {
  fill: v-bind(colourThemeBars);
}

.highlighted {
  fill: v-bind(colourThemePopup);
}

</style>
