<script setup>
import MapAppView from "@/views/MapAppView"
import { onMounted, onUnmounted, ref, watch, computed } from "vue"
import { Splitpanes, Pane } from "splitpanes"
import "splitpanes/dist/splitpanes.css"
import { log } from "@/plugin/logger"
import MapNotification from "@/components/MapNotification"
import syncMaps from "@mapbox/mapbox-gl-sync-move"
import MapStyleChange from "@/components/MapStyleChange.vue"
import {useMainStore} from "@/store/mainStore"
import {useSetStore} from "@/store/setStore"
import StoryCarousel from "@/components/StoryCarousel.vue"
import {useDataFetcher} from "@/composables/useCustomerDataFetcher"
import {useI18nStore} from "@/store/localeStore"

const mainStore = useMainStore()
const dataStore = useSetStore()
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const panesNumber = ref(1)
const syncedMaps = ref()
const baseMaps = ref([])

const hexagon7DaysDataDoneFetching = ref(false)
const edge7DaysDataDoneFetching = ref(false)

const baseMapStyles = computed(() => {
  return mainStore.getBasemapStyles
})

function addNewBaseMap (baseMap) {
  baseMaps.value.push(baseMap)
}

function removeBaseMap () {
  baseMaps.value = baseMaps.value.slice(0, baseMaps.value.length - 1)
}

const get9DaysAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-22"
  }

  const currentDate = new Date() // Today
  currentDate.setDate(currentDate.getDate() - 9) // Subtract 8 days

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}
const get1monthAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-01"
  }

  const currentDate = new Date() // Today
  currentDate.setMonth(currentDate.getMonth() - 1) // Subtract 1 month

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", filters: {min_date: get9DaysAgoDate()} },
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", filters: {min_date: get9DaysAgoDate()} },
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", filters: {} },
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", filters: {} },
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", filters: {} },
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", filters: {} },
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", filters: {} },
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", filters: {} },
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", filters: {} },
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", filters: {min_date: get1monthAgoDate()} },
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", filters: {min_date: get1monthAgoDate()} },
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", filters: {} },
  { workerName: "vehicleRegNos", filename: "stops", setName: "vehicleRegNos", queryFunction: "get_regno", message: "get_regno is requested", filters: {} }
]

const apiConfigurationsRef = ref(apiConfigurations)

const findSetNameByWorkerName = (workerName) => {
  const config = apiConfigurations.find(config => config.workerName === workerName)
  return config ? config.setName : null
}

const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is fetched!`)

  const setNames = [].concat(findSetNameByWorkerName(workerName))
  setNames.map(dataSetName => dataStore.setAggregate(dataSetName, data.result))

  if (workerName === "hexesAPI") hexagon7DaysDataDoneFetching.value = true
  if (workerName === "edgesAPI") edge7DaysDataDoneFetching.value = true
}

const handleError = (error) => {
  log("error", `Error occurred: ${error}`)
}

const { fetchData, registerListeners } = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady,
  onError: handleError
})

onMounted(() => {
  log("info", "Initiated the 'customermapview' component.")
  registerListeners()
  fetchData()
})


watch(
    () => baseMaps.value.length,
    () => {
      if (baseMaps.value.length > 1) {
        if (typeof syncedMaps.value === "function") {
          syncedMaps.value()
        }
        syncedMaps.value = syncMaps(baseMaps.value)
      }
    }
)

</script>

<template>
  <map-style-change :styles="baseMapStyles" />
  <story-carousel />
  <!--  <loader-overlay v-if="!hexagon7DaysDataDoneFetching" />-->
  <splitpanes
    vertical
    class="mappy-map-main default-theme"
  >
    <pane
      min-size="20"
      size="75"
    >
      <div
        v-if="!mainStore.getNotificationFlag"
        class="notifications"
      >
        <MapNotification
          :layer-name="translate('layerLoader->Stops')"
          :loading="hexagon7DaysDataDoneFetching"
        />
        <MapNotification
          :layer-name="translate('layerLoader->Trips')"
          :loading="edge7DaysDataDoneFetching"
        />
      </div>
      <map-app-view
        map-module-state-name="mainMap"
        @base-map-updated="addNewBaseMap"
      />
    </pane>
    <pane>
      <splitpanes
        id="splitter"
        horizontal
      >
        <pane
          v-for="i in panesNumber"
          :key="i + '_map_app_pane'"
        >
          <map-app-view
            :map-module-state-name="'mapState_' + i"
            @base-map-updated="addNewBaseMap"
            @base-map-removed="removeBaseMap"
          />
        </pane>
      </splitpanes>
      <div class="sheets_container">
        <div class="sheets_border" />
        <div class="sheets">
          <button
            id="sheet-button-add"
            class="sheet-button"
            @click="panesNumber++"
          >
            +
          </button>
          <button
            v-if="panesNumber > 1"
            id="sheet-button-remove"
            class="sheet-button"
            @click="panesNumber--"
          >
            -
          </button>
        </div>
      </div>
    </pane>
  </splitpanes>
</template>

<style>
body {
  overflow: hidden;
}

body * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mappy-map-main {
  margin-left: 60px;
  width: 100%;
  height: 100%;
}

#splitter {
  height: 98vh;
}

.sheets_container {
  width: 100%;
  height: 2vh;
  background-color: #041e41;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.sheets_border {
  z-index: 9;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  background-color: #e8008a;
  height: 220px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  clip-path: polygon(100% 100%, 100% 0, 100% 100%, 50% 90%, 0 100%);
  transition: transform 0.4s ease;
}

.sheets {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #041e41;
  height: 200px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  clip-path: polygon(100% 100%, 100% 0, 100% 100%, 50% 90%, 0 100%);
  transition: transform 0.4s ease;
}

.sheets:hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Adjust the scale factor as desired */
  height: 25px;
  transform: scale(1);
}

.sheet-button {
  background-color: #e8008a;
  color: #e39cab;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: none;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 15px rgba(1, 1, 1, 0.1);
  font-size: 16px;
  text-align: center;
  outline: none;
  align-self: center;
  font-weight: bolder;
}

.sheet-button:hover {
  transform: scale(1.1);
}

.splitpanes__pane {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.splitpanes__splitter {
  border-left: 1px solid #e39cab;
  background-color: #e39cab;
}

.notifications {
  max-height: 15vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 8px;
  top: 0;
  z-index: 5;
  padding-left: 0px;
  padding-top: 2px;
}

</style>
