<script setup>
import { ref, watchEffect, defineEmits } from "vue"
import { authService } from "@/services/AuthService"
import { log } from "@/plugin/logger"
import ModalComponent from "@/components/ModalComponent.vue"
import {useRouter} from "vue-router"
import {useMainStore} from "@/store/mainStore"

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
const store = useMainStore()
const router = useRouter()
const dialog = ref()
const isActive = ref(false)

const requiredRole = process.env.VUE_APP_KEYCLOAK_REQUIRED_ROLE
const HORequiredRole = [process.env.VUE_APP_AZUREAD_REQUIRED_ROLE]

const loggedInWith = ref("")

const setAuthText = () => {
  if (authService.keycloak.authenticated) {
    const userEmail = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : JSON.stringify((authService.keycloak))
    if (!authService.keycloak.hasResourceRole(requiredRole)) {
      log("info", userEmail + " tried to get into mappy, but is lacking access.")
      loggedInWith.value = "Sorry " + userEmail + ", you haven't unlocked mappy yet😳. Reach out to Sara Sylvan to learn how to get access."
    }
    else if (authService.keycloak.hasResourceRole(requiredRole)) {
      loggedInWith.value = userEmail + " is logged with customer id."
    }
  }
  else if (authService.getADUser()) {
    const userEmail = authService.getADUser() ? authService.getADUser() : JSON.stringify((authService.login))
    if (!authService.hasADRoles(HORequiredRole)) {
      log("info", userEmail + " tried to get into mappy via Azure, but is lacking access.")
      loggedInWith.value = "Sorry " + userEmail + ", you haven't unlocked mappy yet😳. Reach out to Sara Sylvan to learn how to get access."
    }
    else if (authService.hasADRoles(HORequiredRole)) {
      loggedInWith.value = userEmail + " is logged in with global."
    }
  }
}


const loginWithKeycloak = () => {
  authService.signInWithKeycloak().then((authenticated) => {
    if (authenticated) {
      store.setBearerToken(authService.keycloak.token)
      router.push({ name: "CustomerMapView" })
      closeModal()
    }
    setAuthText()
  })
}

const loginWithAzure = () => {
  authService.signInWithMsal().then((authenticated) => {
    if (authenticated) {
      store.setBearerToken(authService.login.idToken)
      router.push({ name: "FleetView" })
      closeModal()
    }
    setAuthText()
  })
}

function toggleModal () {
  isActive.value = !isActive.value
}

watchEffect(() => {
  if (isActive.value && dialog.value) {
    dialog.value.showModal()
  } else if (dialog.value) {
    dialog.value.close()
  }
})
const openModal = () => {
  isActive.value = true
}
const closeModal = () => {
  isActive.value = false
}
</script>

<template>
  <div class="login">
    <v-btn
      icon
      @click="toggleModal()"
    >
      <tds-icon
        size="15px"
        name="profile"
      />
    </v-btn>
  </div>

  <ModalComponent
    :is-open="isActive"
    name="login-modal"
    class="modal"
    @modal-close="closeModal"
  >
    <template #header>
      Login
    </template>
    <template #content>
      <div class="auth-choice">
        <v-btn
          class="login-btn"
          variant="outlined"
          @click="loginWithKeycloak()"
        >
          Customer
        </v-btn>
        <v-btn
          class="login-btn"
          variant="outlined"
          @click="loginWithAzure()"
        >
          Global
        </v-btn>
      </div>
    </template>
    <template #footer>
      <p>
        {{ loggedInWith }}
      </p>
    </template>
  </ModalComponent>
</template>

<style scoped>
.login {
  width: 50px;
}

.login-btn {
  margin: 30px;
}

.auth-choice {
  opacity: 1;
  width: 450px;
  margin: 150px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
</style>

